import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    paddingBottom: 0,
    borderTop: `2px solid ${theme.palette.primary.main}`,
    '& > div': {
      background: theme.palette.primary.main,
      borderRadius: '50%',
      padding: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > svg': {
        fill: 'white',
        cursor: 'pointer',
      },
    },

    '& .backContainer': {
      background: ({ backIsDisabled }) =>
        backIsDisabled && theme.palette.ui.gray,
    },

    '& .nextContainer': {
      background: ({ nextIsDisabled }) =>
        nextIsDisabled && theme.palette.ui.gray,
    },
  },
}))
