import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(theme => ({
  container: {
    height: 45,
    width: '100%',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: ({ valueLabelDisplay }) =>
      `${valueLabelDisplay ? '70px' : '25px'} 20px 25px 25px`,

    '& .myLabel': {
      left: 15,
      top: -12,
      fontSize: theme.spacing(2),
      color: theme.palette.primary.main,
      position: 'absolute',
      background: '#fafafa',
      borderRadius: 20,
      padding: '0 4px',
    },
  },
}))
