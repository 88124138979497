import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(theme => ({
  container: {
    width: '100%',
    maxWidth: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 15px',
    '& .icon': {
      width: '80%',
      maxWidth: 400,
      minWidth: 100,
    },
    '& .form': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: 5,
      padding: 20,
      marginTop: 10,
      '& > div': { marginTop: 15 },
      '& > button': { marginTop: 30, width: '60%' },
    },
    '& .forgotPassword': {
      color: theme.palette.primary.main,
      fontSize: theme.spacing(2),
      fontWeight: 'bold',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
}))
