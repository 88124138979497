import { useNavigate } from 'react-router-dom'
import Success from './success'

export default () => {
  const navigate = useNavigate()

  const onHomePageClick = () => {
    navigate('/search')
  }

  return <Success onHomePageClick={onHomePageClick} />
}
