import { useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  viewVisitedSteps,
  viewDeliveryIsAdded,
} from 'src/scenes/_slice/view.slice'
import { useNavigate } from 'react-router-dom'
import { useStyle } from './style'

const CurrentStep = ({ value, name, onClick }) => {
  const classes = useStyle({})

  return (
    <div onClick={onClick} className={classes.currentStep}>
      <span className="value">{value}</span>
      <span className="name">{name}</span>
      <span className="line"></span>
    </div>
  )
}

const Steps = ({ value, isUpdating = false, id, checkForChanges }) => {
  const classes = useStyle({})
  const navigate = useNavigate()
  const visitedSteps = useSelector(viewVisitedSteps)
  const deliveryIsAdded = useSelector(viewDeliveryIsAdded)

  const items = useMemo(
    () =>
      id === 'new'
        ? {
            1: { name: 'Utente Tecnico', path: '/technical-user' },
            2: { name: 'Restaurant', path: '/single-restaurant' },
            3: { name: 'Dati Fiscali', path: '/fiscal-data' },
            4: { name: 'Orari', path: '/timing' },
            5: { name: 'Tavoli', path: '/restaurant-tables' },
            6: { name: 'Opzioni', path: '/restaurant-options' },
            7: {
              name: 'Consegna',
              path: '/deliveries',
            },
            8: { name: 'Nota', path: '/note' },
            9: { name: 'Contrarre', path: '/contract' },
          }
        : {
            1: { name: 'Utente Tecnico', path: '/technical-user' },
            2: { name: 'Restaurant', path: '/single-restaurant' },
            3: { name: 'Dati Fiscali', path: '/fiscal-data' },
            4: { name: 'Orari', path: '/timing' },
            5: { name: 'Tavoli', path: '/restaurant-tables' },
            // 6: { name: 'Nota', path: '/note' },
          },
    [isUpdating],
  )

  const onStepClick = useCallback(
    (path, value) => {
      if (
        id === 'new' &&
        !(
          // value === Math.max(...visitedSteps) + 1 || // next step is selected
          visitedSteps.includes(value) // already visited step is selected
        )
      )
        return
      if (id !== 'new')
        checkForChanges
          ? checkForChanges(`${path}/${id}`)
          : navigate(`${path}/${id}`)
      else navigate(`${path}/new`)
    },
    [id, isUpdating, navigate, visitedSteps, checkForChanges],
  )

  return (
    <div className={classes.container}>
      {Object.keys(items).map(key =>
        Number(key) === value ? (
          <CurrentStep
            onClick={() => onStepClick(items[key].path, Number(key))}
            value={value}
            name={items[key].name}
          />
        ) : (
          <span
            className={
              items[key].name === 'Consegna' && !deliveryIsAdded
                ? 'disabled'
                : 'value'
            }
            onClick={() =>
              items[key].name === 'Consegna' && !deliveryIsAdded
                ? null
                : onStepClick(items[key].path, Number(key))
            }
          >
            {key}
          </span>
        ),
      )}
    </div>
  )
}

export default Steps
