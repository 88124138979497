import { useState, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useToggle } from 'src/helpers/hooks'
import { useNavigate } from 'react-router-dom'
import { loadNote, restaurantNoteView } from '../_slice/restaurant.slice'
import Note from './note'
import { setVisitedSteps, viewDeliveryIsAdded } from '../_slice/view.slice'

export default () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [prints, setPrints] = useToggle(false)
  const [printer, setPrinter] = useToggle(false)
  const [tablet, setTablet] = useToggle(false)
  const [flatMenuEdit, setFlatMenuEdit] = useToggle(false)
  const [noteText, setNoteText] = useState('')

  const deliveryIsAdded = useSelector(viewDeliveryIsAdded)

  const isUpdating = useMemo(() => id !== 'new', [id])

  const noteLocal = useSelector(restaurantNoteView)

  useEffect(() => {
    if (id === 'new') {
      dispatch(setVisitedSteps(7))
      setPrints(noteLocal.prints)
      setPrinter(noteLocal.printer)
      setTablet(noteLocal.tablet)
      setFlatMenuEdit(noteLocal.flatMenuEdit)
      setNoteText(noteLocal.noteText)
      return
    }
  }, [noteLocal])

  const onSaveClick = () => {
    dispatch(loadNote({ prints, printer, tablet, flatMenuEdit, noteText }))
    navigate('/contract/new')
  }

  const onBackClick = () => {
    if (isUpdating) navigate(`/restaurant-tables/${id}`)
    else {
      if (deliveryIsAdded) navigate('/deliveries/new')
      else navigate('/restaurant-options/new')
    }
  }

  return (
    <Note
      {...{
        prints,
        setPrints,
        printer,
        setPrinter,
        tablet,
        setTablet,
        flatMenuEdit,
        setFlatMenuEdit,
        noteText,
        setNoteText,
        onSaveClick,
        onBackClick,
        id,
        isUpdating,
      }}
    />
  )
}
