import { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { useStyle } from './style'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const PdfViewer = ({ file, pageHeight }) => {
  const classes = useStyle({})
  const [numPages, setNumPages] = useState(null)

  const { clientWidth } = document.body

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  return (
    <Document
      file={file}
      onLoadSuccess={onDocumentLoadSuccess}
      onLoadError={console.error}
    >
      {Array.from(new Array(numPages), (el, index) => (
        <Page
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          width={clientWidth - 60}
          // scale={0.9}
        />
      ))}
    </Document>
  )
}

export default PdfViewer
