import axios from 'axios'
import { reqWrapper } from 'src/helpers/queryWrapper'
import { getRequest } from 'src/setup/config/axios'

export const insertUser = reqWrapper(body =>
  axios.post(`/users`, body).then(data => data),
)

export const updateUser = reqWrapper(body =>
  axios.put(`/users`, body).then(data => data),
)

export const getUserById = reqWrapper(id => getRequest(`/users/${id}`))
