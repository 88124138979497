import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    '& .sigCanvas': {
      border: '1px solid gray',
      marginTop: 5,
    },
    '& .buttons': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 10,
      '& > svg': { fill: theme.palette.ui.red },
    },
  },
}))
