import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStyle } from './style'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'

const FooterNav = ({
  onNextClick,
  onBackClick,
  backIsDisabled,
  nextIsDisabled,
}) => {
  const classes = useStyle({ backIsDisabled, nextIsDisabled })

  const onNext = useCallback(() => {
    if (nextIsDisabled) return
    onNextClick()
  }, [onNextClick])

  const onBack = useCallback(() => {
    if (backIsDisabled) return
    onBackClick()
  }, [onBackClick])

  return (
    <div className={classes.container}>
      <div className="backContainer">
        <ArrowBackIosNewIcon onClick={onBack} />
      </div>
      <div className="nextContainer">
        <ArrowForwardIosIcon onClick={onNext} />
      </div>
    </div>
  )
}

export default FooterNav
