import { alpha, makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(theme => ({
  container: {
    width: '100%',
    border: '1px solid black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 5,
    background: theme.palette.ui.veryDarkGray,

    '& .left': {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& > span': {
        fontSize: theme.spacing(2),
        fontWeight: 'bold',
        marginLeft: 10,
        color: theme.palette.ui.white,
      },
      '& > svg': {
        color: theme.palette.ui.gray,
        cursor: 'pointer',
      },

      '& .homePage': {
        height: 25,
      },
    },

    '& .right': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      '& .lang-icon': {
        width: 25,
        height: 25,
        marginRight: 15,
      },
      '& .logout': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '& > span': {
          height: '100%',
          marginRight: 5,
          color: theme.palette.ui.red,
          fontWeight: 'bold',
        },
        '& > svg': {
          color: theme.palette.ui.red,
        },
      },
    },
  },
  menuOptions: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 8px',
    '& > img': { width: 30, pointer: 'cursor' },
  },
}))
