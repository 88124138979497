import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(theme => ({
  '@keyframes focus': {
    from: {
      transform: 'scale(0.90)',
    },
    to: {
      transform: 'scale(1.05)',
    },
  },
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',

    '& .value': {
      background: theme.palette.primary.main,
      color: theme.palette.ui.white,
      padding: '1px 6px',
      borderRadius: '50%',
      margin: '0 2px',
    },

    '& .disabled': {
      background: '#004c6a52',
      color: theme.palette.ui.white,
      padding: '2px 8px',
      borderRadius: '50%',
      margin: '0 2px',
    },
  },
  currentStep: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,

    '& .value': {
      background: '#318498',
      fontWeight: 'bold',
      animation: '$focus 1.3s ease-in-out infinite',
    },

    '& .currentStepValue': {
      padding: '2px 6px 2px 8px',
    },

    '& .name': {
      marginLeft: 3,
      fontSize: theme.spacing(1.7),
      color: theme.palette.primary.main,
    },
    '& .line': {
      width: '100%',
      display: 'flex',
      flex: 1,
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      height: 12,
      marginLeft: 2,
    },
  },
}))
