import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',
    maxWidth: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 10,

    '& .topSection': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflow: 'auto',
      '& .steps': { width: '100%', padding: 10 },
      '& > p': {
        width: '100%',
        textAlign: 'center',
        fontSize: theme.spacing(2),
        color: theme.palette.primary.main,
        margin: '5px 0',
      },
      '& .body': {
        width: '100%',
        padding: 10,
        '& > div': {
          marginTop: 20,
        },
      },
    },

    '& .buttonContainer': {
      width: '100%',
      padding: '0 10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: 500,
      '& > button': {
        width: '30%',
      },
    },
  },
}))
