import { useCallback, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import {
  restaurantUserView,
  restaurantInfoView,
  restaurantFiscalDataView,
  restaurantTablesView,
  restaurantTimeTablesView,
  restaurantOptionsView,
  restaurantDeliveriesVeiw,
  restaurantNoteView,
  resetRestaurantSlice,
} from '../_slice/restaurant.slice'
import { insertRestaurant } from 'src/services/restaurant'
import Contract from './contract'
import { setVisitedSteps, resetViewSlice } from '../_slice/view.slice'
import { convertObjectToString } from 'src/helpers/functions'

export default () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [signPadIsOpen, setSignPadIsOpen] = useState(false)
  const [signatureImage, setSignatureImage] = useState(null)

  useEffect(() => {
    dispatch(setVisitedSteps(8))
  }, [])

  const user = useSelector(restaurantUserView)
  const resInfo = useSelector(restaurantInfoView)
  const resFiscalData = useSelector(restaurantFiscalDataView)
  const resTables = useSelector(restaurantTablesView)
  const resTimeTables = useSelector(restaurantTimeTablesView)
  const resOptions = useSelector(restaurantOptionsView)
  const resDeliveries = useSelector(restaurantDeliveriesVeiw)
  const note = useSelector(restaurantNoteView)

  const onSaveClick = image => {
    setSignatureImage(image)
    const { location, category, ...restOfResInfo } = resInfo || {}
    const { world, ...restOfResLocation } = location || {}
    const { world: fiscalWorld, ...restOfFiscalData } = resFiscalData
    const body = {
      delivers: resDeliveries.map(({ id, ...item }) => item),
      fiscalData: {
        ...restOfFiscalData,
        city: resFiscalData ? resFiscalData.world.id : '',
      },
      locationData: {
        ...restOfResLocation,
        city: location ? location.world.id : '',
      },
      restaurant: {
        ...restOfResInfo,
        categories: category.map(({ value }) => value),
        note: convertObjectToString({
          ...note,
          note: note.noteText,
        }),
        digitalSign: image,
      },
      user,
      tables: resTables.map(({ id, ...item }) => item),
      timetables: resTimeTables.map(({ id, ...item }) => item),
      options: resOptions.map(({ id, ...item }) => ({
        ...item,
        enable: true,
      })),
    }
    navigator.clipboard.writeText(JSON.stringify(body))
    console.log(body)
    insertRestaurant(body).then(() => {
      navigate('/success')
      resetRestaurantSlice()
      resetViewSlice()
    })
  }

  const onBackClick = () => {
    navigate('/note/new')
  }

  const onConfirmClick = useCallback(() => {
    const lastPage = document.querySelector('[data-page-number="27"]')
    lastPage.scrollIntoView({ behavior: 'smooth' })
    setTimeout(() => {
      setSignPadIsOpen(true)
    }, '1500')
  }, [])

  return (
    <Contract
      {...{
        onBackClick,
        onSaveClick,
        onConfirmClick,
        signPadIsOpen,
        setSignPadIsOpen,
        id,
      }}
    />
  )
}
