import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',
    maxWidth: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 10,

    '& .topSection': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflow: 'auto',

      '& .steps': { width: '100%', padding: 10 },
      '& > p': {
        width: '100%',
        textAlign: 'center',
        fontSize: theme.spacing(2),
        color: theme.palette.primary.main,
        margin: '5px 0',
      },
      '& .body': {
        width: '100%',
        padding: 10,
        '& > div': {
          marginTop: 20,
        },
        '& .addButtonContainer': {
          marginTop: 30,
          '& > svg': {
            fill: theme.palette.ui.green,
            float: 'right',
          },
        },
      },
    },

    '& .buttonContainer': {
      width: '100%',
      padding: '0 10px',
      display: 'flex',
      maxWidth: 500,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },

  modalBody: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > div': {
      marginTop: 20,
    },

    '& > button': {
      width: '40%',
      marginTop: 20,
    },

    '& .cancel': {
      cursor: 'pointer',
      fontWeight: 'bold',
      fontSize: theme.spacing(2),
      color: theme.palette.primary.main,
    },
  },

  deliveryCard: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 5,
    padding: '5px 10px 12px 10px',
    marginTop: 10,
    cursor: 'pointer',
    '& > div': {
      width: '87%',
      '& > div': {
        width: '100%',
        fontSize: theme.spacing(2),
        color: theme.palette.primary.main,
        marginTop: 10,
        '& > span': {
          fontWeight: 'bold',
          fontSize: theme.spacing(2.2),
          color: theme.palette.primary.main,
        },
      },
    },
    '& > svg': {
      fill: theme.palette.primary.main,
    },
  },
}))
