import { Header, Button } from 'src/components'
import { useStyle } from './style'

const Contract = ({ onHomePageClick }) => {
  const classes = useStyle()

  return (
    <div className={classes.container}>
      <div className="topSection">
        <Header name="Nuovo Ristorante" />
        <div className="body">
          <img alt="success" className="heroSuccess" src="hero-success.png" />
          <div>
            <img alt="checkmark" className="checkmark" src="check-mark.png" />
            <p>Il tuo ristorante è stato creato con successo</p>
          </div>
        </div>
      </div>
      <div className="buttonContainer">
        <Button onClick={onHomePageClick}>Torna alla home page</Button>
      </div>
    </div>
  )
}

export default Contract
