import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(theme => ({
  container: {
    height: '100%',
    width: '100%',
    maxWidth: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .search': {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflowY: 'auto',

      '& .body': {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,

        '& > button': {
          width: '35%',
          marginTop: 15,
        },
        '& .description': {
          marginTop: 15,
          fontSize: theme.spacing(2),
          textAlign: 'center',
          color: theme.palette.primary.main,
          '& > span': {
            color: theme.palette.ui.red,
            fontSize: theme.spacing(2),
            fontWeight: 'bold',
          },
        },
        '& .pagination': {
          padding: '10px 0',
        },
      },

      '& .restaurants': {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: 5,
      },
    },
    '& .new-res': { width: '100%', borderRadius: 0 },
  },

  resCard: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 7,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 5,
    marginTop: 10,
    '& .left': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      justifyContent: 'center',
      '& > span': {
        color: theme.palette.primary.main,
        fontSize: theme.spacing(1.5),
      },
      '& .name': {
        fontSize: theme.spacing(2),
        fontWeight: 'bold',
      },
    },

    '& .right': {
      '& > svg': {
        cursor: 'pointer',
        fill: theme.palette.primary.main,
      },
    },
  },
}))
