import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(theme => ({
  container: {
    width: '100%',
    position: 'relative',
    padding: '0 12px',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 5,
    '& > span': {
      position: 'absolute',
      left: 15,
      top: -12,
      color: theme.palette.primary.main,
      fontSize: theme.spacing(2),
      zIndex: 1,
      background: '#fafafa',
      borderRadius: 20,
      padding: '0 4px',
    },
    '& > div': {
      width: '100%',
      minHeight: 45,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& > span': {
        fontSize: theme.spacing(2),
        fontWeight: 'bold',
        color: theme.palette.primary.main,
      },
      '& > svg': {
        cursor: 'pointer',
        fill: theme.palette.primary.main,
      },
    },
  },
}))
