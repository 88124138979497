import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  selectedResId: '',
  catgories: [],
  user: {},
  restaurant: {
    category: [],
    phone: '',
    location: { world: {} },
  },
  fiscalData: {
    world: {},
  },
  tables: [],
  timeTables: [],
  options: [],
  deliveries: [],
  note: {},
}

const restaurantSlice = createSlice({
  name: 'restaurant',
  initialState,
  reducers: {
    loadSelectedResId: (state, { payload }) => ({
      ...state,
      selectedResId: payload,
    }),
    loadCategories: (state, { payload }) => ({ ...state, categories: payload }),
    loadUser: (state, { payload }) => ({ ...state, user: payload }),
    loadRestaurant: (state, { payload }) => ({ ...state, restaurant: payload }),
    loadFiscalData: (state, { payload }) => ({ ...state, fiscalData: payload }),
    loadTables: (state, { payload }) => ({ ...state, tables: payload }),
    loadTimeTables: (state, { payload }) => ({ ...state, timeTables: payload }),
    loadOptions: (state, { payload }) => ({ ...state, options: payload }),
    loadDeliveries: (state, { payload }) => ({ ...state, deliveries: payload }),
    loadNote: (state, { payload }) => ({ ...state, note: payload }),
    resetRestaurantSlice: () => initialState,
  },
})

const { actions, reducer } = restaurantSlice
export default reducer

// ACTIONS
export const loadSelectedResId = actions.loadSelectedResId
export const loadCategories = actions.loadCategories
export const loadUser = actions.loadUser
export const loadRestaurant = actions.loadRestaurant
export const loadFiscalData = actions.loadFiscalData
export const loadTables = actions.loadTables
export const loadTimeTables = actions.loadTimeTables
export const loadOptions = actions.loadOptions
export const loadDeliveries = actions.loadDeliveries
export const loadNote = actions.loadNote
export const resetRestaurantSlice = actions.resetRestaurantSlice

// VIEW
export const restaurantSelectedIdView = state => state.restaurant.selectedResId
export const restaurantCategoriesView = state => state.restaurant.categories
export const restaurantUserView = state => state.restaurant.user
export const restaurantInfoView = state => state.restaurant.restaurant
export const restaurantFiscalDataView = state => state.restaurant.fiscalData
export const restaurantTablesView = state => state.restaurant.tables
export const restaurantTimeTablesView = state => state.restaurant.timeTables
export const restaurantOptionsView = state => state.restaurant.options
export const restaurantDeliveriesVeiw = state => state.restaurant.deliveries
export const restaurantNoteView = state => state.restaurant.note
