import { useState } from 'react'
import { useDispatch } from 'react-redux'
import Search from './search'
import { getAllRestaurants } from 'src/services/restaurant'
import { setIsLoading } from '../_slice/view.slice'

export default () => {
  const dispatch = useDispatch()

  const [resName, setResName] = useState('')
  const [isSearched, setIsSearched] = useState(false)
  const [resList, setResList] = useState([])
  const [totalElements, setTotalElements] = useState(0)

  const onSearchClick = (resName, page = 1) => {
    dispatch(setIsLoading(true))
    getAllRestaurants({
      name: resName,
      pagination: { elementsPerPage: 10, page },
    }).then(({ list, totalElements }) => {
      dispatch(setIsLoading(false))
      const customList = list.map(({ name, location, id }) => ({
        name,
        address: location.address,
        city: location.world.value,
        id,
      }))
      setResList(customList)
      setTotalElements(totalElements)
      setIsSearched(true)
    })
  }

  return (
    <Search
      {...{
        totalElements,
        resName,
        setResName,
        onSearchClick,
        resList,
        isSearched,
      }}
    />
  )
}
