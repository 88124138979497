import { useState, useEffect, useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import * as R from 'ramda'
import { errorAlert, warningAlert } from 'src/helpers/alerts'
import SingleRes from './singleRes'
import {
  getSingleRestaurant,
  updateSingleRetaurant,
  getRestaurantCategoriesReq,
  insertAllCategories,
  deleteAllCategories,
} from 'src/services/restaurant'
import { setIsLoading, setVisitedSteps } from '../_slice/view.slice'
import { getLocationReq } from 'src/services/location'
import { worldCitiesView } from '../_slice/world.slice'
import {
  restaurantCategoriesView,
  restaurantInfoView,
  loadRestaurant,
} from '../_slice/restaurant.slice'
import {
  convertCitiesToOptions,
  convertCategoriesToOptions,
} from 'src/helpers/functions'

export default () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [data, setData] = useState({
    category: [],
    phone: '',
    location: { world: {} },
  })

  const [initialData, setInitialData] = useState({
    category: [],
    phone: '',
    location: { world: {} },
  })

  const [initialCategories, setInitialCategories] = useState([])

  const [resCity, setResCity] = useState('')
  const rawCities = useSelector(worldCitiesView)
  const cities = useMemo(
    () => convertCitiesToOptions(rawCities || []),
    [rawCities],
  )
  const cats = useSelector(restaurantCategoriesView)
  const categories = useMemo(
    () => convertCategoriesToOptions(cats || []),
    [cats],
  )

  const resDataLocal = useSelector(restaurantInfoView)

  const loadData = () => {
    dispatch(setIsLoading(true))
    Promise.all([
      getSingleRestaurant(id).then(data => {
        setData(prevState => ({ ...prevState, ...data }))
        setInitialData(prevState => ({ ...prevState, ...data }))
      }),
      getRestaurantCategoriesReq({ idRestaurant: id }).then(data => {
        const retrievedCats = data.list.map(({ id, restaurantCategory }) => ({
          key: restaurantCategory.value,
          value: restaurantCategory.id,
          associationId: id,
        }))
        setData(prevState => ({ ...prevState, category: retrievedCats }))
        setInitialData(prevState => ({ ...prevState, category: retrievedCats }))
        setInitialCategories(
          retrievedCats.map(({ value, associationId }) => ({
            value,
            associationId,
          })),
        )
      }),
    ]).then(() => {
      dispatch(setIsLoading(false))
    })
  }

  useEffect(() => {
    if (id === 'new') {
      dispatch(setVisitedSteps(2))
      setData(resDataLocal)
      return
    }
    loadData()
  }, [id, dispatch])

  const setLocation = query => {
    getLocationReq(query).then(({ list }) => {
      setData(prevState => ({ ...prevState, category: list }))
    })
  }

  const hasChanges = useMemo(
    () => !R.equals(data, initialData),
    [data, initialData],
  )

  const onSaveClick = () => {
    dispatch(setIsLoading(true))
    const { location, category, ...rest } = data
    // getLocationReq(`${location.address} ${resCity}`)
    //   .then(({ data: locData }) => {
    // const { latitude, longitude } = locData[0]
    const customizedCats = data.category.map(({ value }) => value)

    const addedCatsArray = customizedCats.map(id =>
      initialCategories.find(({ value: targetId }) => id === targetId)
        ? null
        : id,
    )

    const filteredAddedCats = addedCatsArray.filter(id => id)

    const removedCatsArray = initialCategories.map(({ value: id }) =>
      customizedCats.find(targetId => id === targetId) ? null : id,
    )

    const filteredRemovedCats = removedCatsArray.filter(id => id)

    let addedCatsIds = []
    let removedCatsIds = []

    filteredAddedCats.forEach(targetId => {
      const found = cats.find(({ id }) => targetId === id)
      addedCatsIds.push(found)
    })

    filteredRemovedCats.forEach(targetId => {
      const found = cats.find(({ id }) => targetId === id)
      removedCatsIds.push(found)
    })

    const finalAddedCats = addedCatsIds.map(item => ({
      idRestaurant: id,
      restaurantCategory: item,
    }))

    const finalRemovedCats = removedCatsIds.map(item => {
      const { associationId } = initialCategories.find(
        ({ value: targetId }) => targetId === item.id,
      )
      return {
        idRestaurant: Number(id),
        id: associationId,
        restaurantCategory: item,
      }
    })

    Promise.all([
      updateSingleRetaurant({
        ...rest,
        location: { ...location },
      }),
      finalAddedCats.length && insertAllCategories(finalAddedCats),
      finalRemovedCats.length && deleteAllCategories(finalRemovedCats),
    ]).then(() => {
      loadData()
    })
    // })
    // .catch(err => {
    //   errorAlert({
    //     message: err,
    //   })
    // })
  }

  const checkForChanges = pathToNavigate => {
    if (hasChanges) {
      warningAlert({
        message: 'modifiche non salvate',
        detail: 'Sei sicuro di procedere senza salvare?',
        callback: () => navigate(pathToNavigate),
      })
    } else navigate(pathToNavigate)
  }

  const onNextClick = () => {
    // uddating by calling API's
    if (id !== 'new') {
      checkForChanges(`/fiscal-data/${id !== 'new' ? id : 'new'}`)
    } else {
      dispatch(setIsLoading(true))
      const { location, category, ...rest } = data
      // getLocationReq(`${location.address} ${resCity}`)
      //   .then(({ data: locData }) => {
      // const { latitude, longitude } = locData[0]
      // local stuff
      dispatch(
        loadRestaurant({
          location: { ...location },
          category,
          ...rest,
        }),
      )
      dispatch(setIsLoading(false))
      navigate(`/fiscal-data/${id !== 'new' ? id : 'new'}`)
      // })
      // .catch(err => {
      //   errorAlert({
      //     message: err,
      //   })
      // })
    }
  }

  const onBackClick = () => {
    if (id !== 'new')
      checkForChanges(`/technical-user/${id !== 'new' ? id : 'new'}`)
    else navigate(`/technical-user/${id !== 'new' ? id : 'new'}`)
  }

  const changeData = (key, childKey = '', value) =>
    setData(prevState =>
      !childKey
        ? { ...prevState, [key]: value }
        : { ...prevState, [key]: { ...prevState[key], [childKey]: value } },
    )

  return (
    <SingleRes
      {...{
        changeData,
        categories,
        cities,
        data,
        id,
        isNew: id === 'new',
        onNextClick,
        onBackClick,
        setLocation,
        resCity,
        setResCity,
        onSaveClick,
        hasChanges,
        checkForChanges,
      }}
    />
  )
}
