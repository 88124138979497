import { useStyle } from './style'
import { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import LogoutIcon from '@mui/icons-material/Logout'
import { useNavigate } from 'react-router-dom'
import MyMenu from '../menu'

const Header = ({ name }) => {
  const classes = useStyle({})
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState()
  const [language, setLanguage] = useState('IT')
  const open = Boolean(anchorEl)

  const handleClick = event => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const logOut = () => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    console.log('log out')
    navigate('/')
  }

  const onLangClick = selectedLang => {
    setLanguage(selectedLang)
    handleClose()
  }

  return (
    <div className={classes.container}>
      <div className="left">
        {/* <MenuIcon fontSize="small" /> */}
        <img
          className="homePage"
          onClick={() => navigate('/search')}
          alt="language"
          src="/homePage.png"
        />

        <span>{name}</span>
      </div>
      <div className="right">
        <img
          onClick={handleClick}
          className="lang-icon"
          alt="language"
          src={language === 'EN' ? '/united-kingdom.png' : '/italy.png'}
        />
        <MyMenu open={open} anchorEl={anchorEl} handleClose={handleClose}>
          <div className={classes.menuOptions}>
            <img
              onClick={() => onLangClick('IT')}
              alt="language"
              src="/italy.png"
            />
            {/* <img
              onClick={() => onLangClick('EN')}
              alt="language"
              src="/united-kingdom.png"
            /> */}
          </div>
        </MyMenu>
        <div onClick={logOut} className="logout">
          <span>Logout</span>
          <LogoutIcon fontSize="small" />
        </div>
      </div>
    </div>
  )
}

export default Header
