import { useState, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadOptions, restaurantOptionsView } from '../_slice/restaurant.slice'
import { setVisitedSteps } from '../_slice/view.slice'
import { setIsLoading, setDeliveryAdded } from '../_slice/view.slice'
import { useNavigate } from 'react-router-dom'
import { getOptoinsReq, updateSingleOptionReq } from 'src/services/restaurant'
import { useParams } from 'react-router-dom'
import ResOptions from './resOptions'

export default () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [itemsList, setItemsList] = useState([])
  // const [option, setOption] = useState('')
  // const [cash, setCash] = useToggle(true)
  // const [enable, setEnable] = useToggle(true)
  const isUpdating = useMemo(() => id !== 'new', [id])

  const loadOptionsFromAPI = (triggerLoading = false, page = 1) => {
    getOptoinsReq({
      idRestaurant: id,
    }).then(res => {
      if (triggerLoading) dispatch(setIsLoading(false))
    })
  }

  const optionsLocal = useSelector(restaurantOptionsView)

  useEffect(() => {
    if (id === 'new') {
      dispatch(setVisitedSteps(6))
      setItemsList(optionsLocal)
      return
    }
    dispatch(setIsLoading(true))
    loadOptionsFromAPI(true)
  }, [id, optionsLocal])

  const onNextClick = () => {
    if (isUpdating) {
      updateSingleOptionReq({}).then(res => {})
    } else {
      dispatch(loadOptions(itemsList))
      const foundDelivery = itemsList.find(({ option }) => option === 'DELIVER')
      dispatch(setDeliveryAdded(foundDelivery ? true : false))
    }
  }

  const onBackClick = () => {
    if (isUpdating) navigate(`/restaurant-tables/${id}`)
    else navigate('/restaurant-tables/new')
  }

  const removeItem = targetId =>
    setItemsList(prevState => prevState.filter(({ id }) => id !== targetId))

  const addItem = item => setItemsList(prevState => [...prevState, item])

  const editItem = targetItem =>
    setItemsList(prevState =>
      prevState.map(item => (targetItem.id === item.id ? targetItem : item)),
    )

  return (
    <ResOptions
      {...{
        removeItem,
        addItem,
        editItem,
        onNextClick,
        onBackClick,
        itemsList,
        id,
        isUpdating,
      }}
    />
  )
}
