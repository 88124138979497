import { useState, useEffect, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import * as R from 'ramda'
import SingleRes from './technicalUser'
import { getSingleRestaurant } from 'src/services/restaurant'
import { updateUser, getUserById } from 'src/services/user'
import { setIsLoading, setVisitedSteps } from '../_slice/view.slice'
import { loadUser, restaurantUserView } from '../_slice/restaurant.slice'
import { warningAlert } from 'src/helpers/alerts'

export default () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [data, setData] = useState({
    name: '',
    surname: '',
    email: '',
    phone: '',
  })
  const [initialData, setInitialData] = useState({
    name: '',
    surname: '',
    email: '',
    phone: '',
  })

  const [userData, setUserData] = useState({})

  const userDataLocal = useSelector(restaurantUserView)

  const loadData = () => {
    dispatch(setIsLoading(true))
    getSingleRestaurant(id)
      .then(data => {
        setData(prevState => ({ ...prevState, ...data.user }))
        setInitialData(prevState => ({ ...prevState, ...data.user }))
        getUserById(data.user.id).then(userRes => {
          setUserData(userRes)
        })
      })
      .then(() => {
        dispatch(setIsLoading(false))
      })
  }

  useEffect(() => {
    if (id === 'new') {
      dispatch(setVisitedSteps(1))
      setData(userDataLocal)
      return
    }
    loadData()
  }, [id, dispatch, userDataLocal])

  const hasChanges = useMemo(
    () => !R.equals(data, initialData),
    [data, initialData],
  )

  const onSaveClick = () => {
    dispatch(setIsLoading(true))
    // uddating by calling API's
    updateUser({
      ...userData,
      ...data,
    }).then(() => {
      loadData()
    })
  }

  const checkForChanges = pathToNavigate => {
    if (hasChanges) {
      warningAlert({
        message: 'modifiche non salvate',
        detail: 'Sei sicuro di procedere senza salvare?',
        callback: () => navigate(pathToNavigate),
      })
    } else navigate(pathToNavigate)
  }

  const onNextClick = () => {
    // is updating
    if (id !== 'new') {
      checkForChanges(`/single-restaurant/${id !== 'new' ? id : 'new'}`)
    }
    // local stuff
    else {
      dispatch(loadUser(data))
      dispatch(setIsLoading(false))
      navigate(`/single-restaurant/${id !== 'new' ? id : 'new'}`)
    }
  }

  const onBackClick = () => {
    if (id !== 'new') checkForChanges('/search')
    else navigate('/search')
  }

  const changeData = (key, childKey = '', value) =>
    setData(prevState =>
      !childKey
        ? { ...prevState, [key]: value }
        : { ...prevState, [key]: { ...prevState[key], [childKey]: value } },
    )

  return (
    <SingleRes
      {...{
        changeData,
        data,
        id,
        isNew: id === 'new',
        onNextClick,
        onBackClick,
        hasChanges,
        onSaveClick,
        checkForChanges,
      }}
    />
  )
}
