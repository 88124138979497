import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',
    maxWidth: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& .topSection': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flex: 1,
      '& .body': {
        width: '100%',
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        '& .heroSuccess': {
          maxHeight: 350,
        },

        '& > div': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          '& .checkmark': {
            maxHeight: 100,
          },
          '& > p': {
            fontSize: theme.spacing(2.5),
            fontWeight: 'bold',
            color: theme.palette.ui.green,
            textAlign: 'center',
          },
        },
      },
    },

    '& .buttonContainer': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: 500,
      '& > button': {
        width: '100%',
        borderRadius: 0,
      },
    },
  },
}))
