import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(theme => ({
  ul: {
    '& .MuiPaginationItem-root': {
      color: theme.palette.primary.main,
    },
    '& .css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
    },
    '$ .css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected:hover':
      {
        backgroundColor: theme.palette.primary.main,
      },
  },
}))
