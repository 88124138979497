import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',
    maxWidth: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 10,

    '& .topSection': {
      width: '100%',
      maxHeight: '75%',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      justifyContent: 'space-between',
      '& .pdfContainer': {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        '& .steps': { width: '100%', padding: 10 },
        '& > p': {
          width: '100%',
          textAlign: 'center',
          fontSize: theme.spacing(2),
          color: theme.palette.primary.main,
          margin: '5px 0',
        },
        '& .body': {
          width: '100%',
          padding: 10,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxHeight: '80%',
          flex: 1,
          '& .pdfWrapper': {
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: 5,
            boxShadow: '2px 2px 2px 2px red',
            overflow: 'auto',
            marginTop: 20,
            width: '90%',
            maxHeight: `100%`,
            // maxWidth: 200,
            '& canvas': {
              width: '100% !important',
            },
          },
        },
      },
      '& .confirmButtonContainer': {
        padding: 10,
        paddingBottom: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& > button': {
          width: '60%',
          fontSize: theme.spacing(1.5),
          backgroundColor: theme.palette.ui.green,
          color: theme.palette.primary.main,
        },
      },
    },

    '& .buttonContainer': {
      width: '100%',
      padding: '0 10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: 500,
      '& > button': {
        width: '30%',
      },
    },
  },
  modalBody: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .description': {
      fontSize: theme.spacing(1.8),
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
    '& .signContainer': {
      fontWeight: 'bold',
      marginTop: 10,
    },
  },
}))
