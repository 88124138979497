import {
  Header,
  Steps,
  FooterNav,
  Button,
  PdfViewer,
  SignaturePad,
  MyModal,
} from 'src/components'
import { useStyle } from './style'

const Contract = ({
  onConfirmClick,
  onBackClick,
  onSaveClick,
  id,
  signPadIsOpen,
  setSignatureImage,
  setSignPadIsOpen,
}) => {
  const classes = useStyle()

  return (
    <div className={classes.container}>
      <div className="topSection">
        <div className="pdfContainer">
          <Header name="Nuovo Ristorante" />
          <div className="steps">
            <Steps value={9} id={id} />
          </div>
          <div className="body">
            <div className="pdfWrapper">
              <PdfViewer
                onTrim={setSignatureImage}
                file="/contract.pdf"
                pageHeight={500}
              />
            </div>
          </div>
          <MyModal
            open={signPadIsOpen}
            handleClose={() => {
              setSignPadIsOpen(false)
            }}
          >
            <div className={classes.modalBody}>
              <div className="description">
                Firmato in nome e per conto del Ristorante
              </div>
              <div className="signContainer">
                <SignaturePad onTrim={onSaveClick} />
              </div>
            </div>
          </MyModal>
        </div>
        <div className="confirmButtonContainer">
          <Button onClick={onConfirmClick} className="new-res">
            Conferma di aver letto integralmente e accettato il presente Accordo
          </Button>
        </div>
      </div>
      <div className="buttonContainer">
        <FooterNav onBackClick={onBackClick} nextIsDisabled />
      </div>
    </div>
  )
}

export default Contract
