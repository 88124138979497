export const myOptions = {
  weekDays_IT: [
    { key: 'Tutti', value: 'ALL' },
    { key: 'Lunedì', value: 'MO', isDefault: true },
    { key: 'Martedì', value: 'TU' },
    { key: 'Mercoledì', value: 'WE' },
    { key: 'Giovedì', value: 'TH' },
    { key: 'Venerdì', value: 'FR' },
    { key: 'Sabato', value: 'SA' },
    { key: 'Domenica', value: 'SU' },
  ],
  resOptions_EN: [
    { key: 'Inside', value: 'INSIDE' },
    { key: 'Booking', value: 'BOOKING' },
    { key: 'Preoder', value: 'PREORDER' },
    { key: 'Takeaway', value: 'TAKEAWAY' },
    { key: 'Deliver', value: 'DELIVER' },
    { key: 'Waiter', value: 'WAITER' },
  ],
  resOptions_IT: [
    { key: 'Ordine interno', value: 'INSIDE' },
    { key: 'Prenotazione', value: 'BOOKING' },
    { key: 'Pre-ordine', value: 'PREORDER' },
    { key: 'Asporto', value: 'TAKEAWAY' },
    { key: 'Consegna', value: 'DELIVER' },
    { key: 'Cameriere', value: 'WAITER' },
  ],
  boolean_IT: [
    { key: 'Si', value: true },
    { key: 'No', value: false },
  ],
}
