import { useCallback, useState } from 'react'
import {
  Button,
  Header,
  Steps,
  Select,
  MyModal,
  MyTimePicker,
  FooterNav,
} from 'src/components'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { dateToHoursAndMinutes, extractKeyByValue } from 'src/helpers/functions'
import { myOptions } from 'src/helpers/selectOptions'
import { useStyle } from './style'

const TimeCard = ({ day, from, to, onRemoveClick, onEditClick }) => {
  const classes = useStyle()

  return (
    <div onClick={onEditClick} className={classes.timeCard}>
      <div>
        <span>{` ${extractKeyByValue(myOptions.weekDays_IT, day)} `}</span> from{' '}
        <span>{` ${from} `}</span> to
        <span>{` ${to}`}</span>
      </div>
      <DeleteOutlineIcon fontSize="large" onClick={onRemoveClick} />
    </div>
  )
}

const Timing = ({
  timesList,
  isUpdating,
  updateItemAPI,
  addItemAPI,
  removeItem,
  deleteItemAPI,
  addItem,
  editItem,
  onNextClick,
  onBackClick,
  id,
}) => {
  const classes = useStyle()

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const [selectedItem, setSelectedItem] = useState('')
  const [weekday, setWeekday] = useState('')
  const [timeFrom, setTimeFrom] = useState('')
  const [timeTo, setTimeTo] = useState('')

  const toggleModal = useCallback(
    () => setModalIsOpen(prevState => !prevState),
    [setModalIsOpen],
  )

  const onCancelClick = () => {
    setWeekday('')
    setTimeFrom('')
    setTimeTo('')
    toggleModal('')
  }

  const onEditClick = useCallback(
    targetId => {
      setSelectedItem(targetId)
      timesList.forEach(({ id, timeFrom, timeTo, weekday }) => {
        if (targetId === id) {
          setWeekday(weekday)
          setTimeFrom(timeFrom)
          setTimeTo(timeTo)
          toggleModal()
        }
      })
    },
    [
      timesList,
      setSelectedItem,
      setWeekday,
      setTimeFrom,
      setTimeTo,
      toggleModal,
    ],
  )

  const onRemoveClick = useCallback(
    id => {
      if (isUpdating) {
        deleteItemAPI(id)
      } else {
        removeItem(id)
      }
    },
    [isUpdating, removeItem, deleteItemAPI],
  )

  const onConfirmClick = () => {
    // calling API's
    if (isUpdating) {
      // inserting item
      if (!selectedItem) {
        const item = { weekday, timeFrom, timeTo }
        addItemAPI(item)
        // updating item
      } else {
        const item = {
          id: selectedItem,
          weekday,
          timeFrom,
          timeTo,
        }
        updateItemAPI(item)
      }
    }

    // doing local stuff
    if (!selectedItem) {
      addItem({
        weekday,
        timeFrom,
        timeTo,
        id: !timesList.length ? 1 : timesList[timesList.length - 1].id + 1,
      })
    } else
      editItem({
        weekday,
        timeFrom,
        timeTo,
        id: selectedItem,
      })

    setWeekday('')
    setTimeFrom('')
    setTimeTo('')
    toggleModal()
    setSelectedItem('')
  }

  return (
    <div className={classes.container}>
      <div className="topSection">
        <Header name="Nuovo Ristorante" />
        <div className="steps">
          <Steps value={4} isUpdating={isUpdating} id={id} />
        </div>
        <p>
          Definisci gli orari di servizio <br />
          del ristorante
        </p>
        <div className="body">
          <div className="cardsList">
            {timesList.map(({ weekday, timeFrom, timeTo, id }) => (
              <TimeCard
                day={weekday}
                from={timeFrom}
                to={timeTo}
                onRemoveClick={e => {
                  onRemoveClick(id)
                  e.stopPropagation()
                }}
                onEditClick={() => onEditClick(id)}
              />
            ))}
          </div>
          <div className="addButtonContainer">
            <AddCircleIcon fontSize="large" onClick={toggleModal} />
          </div>
        </div>
      </div>
      <div className="buttonContainer">
        <FooterNav
          onNextClick={onNextClick}
          onBackClick={onBackClick}
          nextIsDisabled={!timesList.length}
        />
        {/* 
        <Button
          disabled={!timesList.length}
          onClick={onNextClick}
          className="new-res"
        >
          Aggiungi Tavoli
        </Button> */}
        <MyModal
          open={modalIsOpen}
          handleClose={toggleModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className={classes.modalBody}>
            <Select
              value={weekday}
              onChange={setWeekday}
              options={myOptions.weekDays_IT}
              label="Giorno"
            />
            <MyTimePicker
              value={timeFrom}
              onChange={value => setTimeFrom(dateToHoursAndMinutes(value))}
              label="Dalle"
            />
            <MyTimePicker
              value={timeTo}
              onChange={value => setTimeTo(dateToHoursAndMinutes(value))}
              label="Alle"
            />
            <Button
              disabled={!(timeFrom && weekday && timeTo)}
              onClick={onConfirmClick}
            >
              Salva
            </Button>
            <div className="cancel" onClick={onCancelClick}>
              Annulla
            </div>
          </div>
        </MyModal>
      </div>
    </div>
  )
}

export default Timing
