import MuiPhoneNumber from 'material-ui-phone-number'
import { useStyle } from './style'

const PhoneInput = ({ value, label = '', onChange }) => {
  const classes = useStyle()

  return (
    <div className={classes.container}>
      <span>{label}</span>
      <MuiPhoneNumber
        defaultCountry={'it'}
        onChange={value => onChange(value)}
        inputClass={classes.input}
        value={value}
      />
    </div>
  )
}

export default PhoneInput
