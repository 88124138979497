import { TextField, Button } from 'src/components'
import { useStyle } from './style'

const Login = ({
  username,
  setUsername,
  password,
  setPassword,
  onLoginClick,
  onForgotClick,
}) => {
  const classes = useStyle()

  return (
    <div className={classes.container}>
      <img className="icon" alt="wh" src="./wh-salesman.png" />
      <div className="form">
        <TextField
          onChange={setUsername}
          value={username}
          placeholder="Username..."
        />
        <TextField
          value={password}
          onChange={setPassword}
          type="password"
          placeholder="Password..."
        />
        <Button onClick={() => onLoginClick(username, password)}>Login</Button>
        <div className="forgotPassword" onClick={onForgotClick}>
          Password dimenticata
        </div>
      </div>
    </div>
  )
}

export default Login
