export const dateToHoursAndMinutes = date => {
  const d = new Date(date)
  const minutes = d.getMinutes()
  return `${d.getHours()}:${minutes === 0 ? '00' : minutes}`
}

export const searchForValueByKey = (list, key, value) =>
  list.find(item => item[key] === value)

export const convertCitiesToOptions = cities =>
  cities.map(city => ({
    key: city.value,
    value: city.id,
  }))

export const convertCategoriesToOptions = categories =>
  categories.map(category => ({ key: category.value, value: category.id }))

export const convertObjectToString = items => {
  let result = ''
  Object.keys(items).forEach(key => (result += `${key}=${items[key]}\n`))
  return result
}

export const extractKeyByValue = (list, targetKey) => {
  console.log({ list, targetKey })
  const { key } = list.find(({ value }) => value === targetKey) || {
    key: '',
  }
  return key
}
