import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { viewIsLoading } from '../_slice/view.slice'
import Login from '../login'
import Search from '../search'
import SingleRes from '../singleRes'
import FiscalData from '../fiscalData'
import TechnicalUser from '../technicalUser'
import Timing from '../timing'
import ResTables from '../resTables'
import ResOptions from '../resOptions'
import Deliveries from '../deliveries'
import Note from '../note'
import Contract from '../contract'
import Success from '../success'
import { Loading } from 'src/components'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
  HashRouter,
} from 'react-router-dom'
import { useStyle } from './style'

const Body = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname.endsWith('/new')) navigate('/technical-user/new')
    if (pathname.endsWith('/search')) navigate('/search')
    if (pathname.endsWith('/success')) navigate('/success')
    if (pathname === '/') return
    else navigate(pathname)
  }, [])

  return (
    <Routes>
      <Route exact path="/" name="Login Page" element={<Login />} />
      <Route
        exact
        path="/search"
        name="Search Restaurants"
        element={<Search />}
      />
      <Route
        exact
        path="/technical-user/:id"
        name="Technical User"
        element={<TechnicalUser />}
      />
      <Route
        exact
        path="/single-restaurant/:id"
        name="Single Restaurant"
        element={<SingleRes />}
      />
      <Route
        exact
        path="/fiscal-data/:id"
        name="Fiscal Data"
        element={<FiscalData />}
      />
      <Route exact path="/timing/:id" name="Timing" element={<Timing />} />
      <Route
        exact
        path="/restaurant-tables/:id"
        name="Restaurant Tables"
        element={<ResTables />}
      />
      <Route
        exact
        path="/restaurant-options/:id"
        name="Restaurant Options"
        element={<ResOptions />}
      />
      <Route
        exact
        path="/deliveries/:id"
        name="Deliveries"
        element={<Deliveries />}
      />
      <Route exact path="/note/:id" name="Note" element={<Note />} />
      <Route
        exact
        path="/contract/:id"
        name="Contract"
        element={<Contract />}
      />
      <Route exact path="/success" name="Success" element={<Success />} />
    </Routes>
  )
}

const App = () => {
  const classes = useStyle()
  const open = useSelector(viewIsLoading)

  return (
    <div className={classes.root}>
      <Loading open={open} />
      <HashRouter>
        <Body />
      </HashRouter>
    </div>
  )
}

export default App
