import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(theme => ({
  container: {
    width: '100%',
    '& > div': {
      '& > div': { borderRadius: 5 },
      position: 'relative',
      '& > span': {
        position: 'absolute',
        left: 15,
        top: -12,
        color: theme.palette.primary.main,
        fontSize: theme.spacing(2),
        zIndex: 1,
        background: '#fafafa',
        borderRadius: 20,
        padding: '0 4px',
      },
    },
  },
  textField: {
    width: '100%',
    '& > div': {
      height: 50,
      borderRadius: 5,
    },

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: `${theme.palette.primary.main} !important`,
      },
      // '&:hover fieldset': {
      //   borderColor: theme.palette.primary.main,
      // },
      // '&.Mui-focused fieldset': {
      //   borderColor: theme.palette.primary.main,
      // },
    },
  },
}))
