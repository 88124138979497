import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { loginReq } from 'src/services/auth'
import { setIsLoading } from '../_slice/view.slice'
import { loadCities } from '../_slice/world.slice'
import { loadCategories } from '../_slice/restaurant.slice'
import { getCities } from 'src/services/world'
import { getCategories } from 'src/services/restaurant'

import Login from './login'

export default () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onLoginClick = (username, password) => {
    dispatch(setIsLoading(true))
    Promise.all([
      loginReq({ username, password }).then(({ jwt, refreshJwt }) => {
        localStorage.setItem('accessToken', jwt)
        localStorage.setItem('refreshToken', refreshJwt)
        dispatch(setIsLoading(false))
        navigate('/search')
      }),
      getCities({}).then(({ list }) => {
        dispatch(loadCities(list))
      }),
      getCategories({}).then(({ list }) => {
        dispatch(loadCategories(list))
      }),
    ])
  }

  return (
    <Login
      {...{ username, setUsername, password, setPassword, onLoginClick }}
    />
  )
}
