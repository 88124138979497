import { combineReducers } from '@reduxjs/toolkit'
import worldReducer from 'src/scenes/_slice/world.slice'
import restaurantReducer from 'src/scenes/_slice/restaurant.slice'
import viewSlice from 'src/scenes/_slice/view.slice'

const rootReducer = combineReducers({
  world: worldReducer,
  restaurant: restaurantReducer,
  view: viewSlice,
})

export default rootReducer
