import clsx from 'clsx'
import { InputBase } from '@material-ui/core'
import { useStyle } from './style'

const TextField = ({
  placeholder = '',
  value,
  type = '',
  autoComplete = '',
  hasError,
  className,
  onChange,
  label = '',
  multiline,
}) => {
  const classes = useStyle({ hasError, multiline })

  return (
    <div className={classes.container}>
      <span>{label}</span>
      <InputBase
        placeholder={placeholder}
        onChange={e => onChange(e.target.value)}
        value={value}
        classes={{ root: clsx(className, classes.root), input: classes.input }}
        type={type}
        autoComplete={autoComplete}
        fullWidth
        multiline={multiline}
      />
    </div>
  )
}

export default TextField
