import { useCallback, useState } from 'react'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import TextField from '@mui/material/TextField'
import { useStyle } from './style'

const MyTimePicker = ({ label, onChange, value }) => {
  const classes = useStyle()
  const [time, setTime] = useState('')

  const handleChange = newValue => {
    setTime(newValue)
    onChange(newValue)
  }

  const convertToDate = useCallback(() => {
    const splittedDate = value.split(':')
    const date = new Date()
    date.setHours(splittedDate[0])
    date.setMinutes(splittedDate[1])
    return date
  }, [value])

  return (
    <div className={classes.container}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div>
          <span>{label}</span>
          <TimePicker
            value={value instanceof Date ? value : convertToDate(value) || time}
            onChange={handleChange}
            renderInput={params => (
              <TextField className={classes.textField} {...params} />
            )}
          />
        </div>
      </LocalizationProvider>
    </div>
  )
}

export default MyTimePicker
