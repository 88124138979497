import { useRef, useCallback } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Button } from 'src/components'
import { useStyle } from './style'

const SignaturePad = ({ onTrim }) => {
  const classes = useStyle({})
  let sigRef = useRef()

  const clear = useCallback(() => {
    sigRef.current?.clear()
  }, [sigRef])

  const trim = useCallback(() => {
    if (!sigRef.current) return
    const trimmed = sigRef.current?.getTrimmedCanvas().toDataURL('image/png')
    onTrim(trimmed)
  }, [sigRef])

  return (
    <div className={classes.container}>
      <SignatureCanvas
        penColor="green"
        canvasProps={{ width: 300, height: 200, className: 'sigCanvas' }}
        ref={sigRef}
      />
      <div className="buttons">
        <DeleteOutlineIcon onClick={clear} />
        <Button onClick={trim}>Salva</Button>
      </div>
    </div>
  )
}

export default SignaturePad
