import { useCallback } from 'react'
import {
  TextField,
  Header,
  Steps,
  Select,
  FooterNav,
  Button,
} from 'src/components'
import { useStyle } from './style'

const FiscalData = ({
  isNew,
  data = {},
  cities,
  changeData,
  onNextClick,
  onBackClick,
  id,
  hasChanges,
  onSaveClick,
  checkForChanges,
}) => {
  const classes = useStyle()

  const onDataChange = useCallback(
    (key, subKey = '') =>
      value => {
        changeData(key, subKey, value)
      },
    [changeData],
  )

  return (
    <div className={classes.container}>
      <div className="topSection">
        <Header name={!isNew ? 'Modifica Ristorante' : 'Nuovo Ristorante'} />
        <div className="body">
          <div className="steps">
            <Steps value={3} id={id} checkForChanges={checkForChanges} />
          </div>
          {/* <div className="title">Dati Fiscali</div> */}
          <div className="card">
            <TextField
              label="Nome"
              onChange={onDataChange('fiscalData', 'name')}
              value={data.fiscalData.name}
            />
            <TextField
              multiline
              label="Indirizzo"
              onChange={onDataChange('fiscalData', 'address')}
              value={data.fiscalData.address}
            />
            <Select
              options={cities}
              label="Città"
              onChange={value =>
                changeData('fiscalData', 'world', {
                  ...data.fiscalData.world,
                  id: value,
                })
              }
              value={data.fiscalData.world.id}
            />
            <TextField
              label="Partita IVA"
              onChange={onDataChange('fiscalData', 'vat')}
              value={data.fiscalData.vat}
            />
            <TextField
              label="IBAN"
              onChange={onDataChange('fiscalData', 'iban')}
              value={data.fiscalData.iban}
            />
            <TextField
              label="PEC"
              onChange={onDataChange('fiscalData', 'pec')}
              value={data.fiscalData.pec}
            />
            <TextField
              label="SDI"
              onChange={onDataChange('fiscalData', 'sdi')}
              value={data.fiscalData.sdi}
            />
          </div>
        </div>
        {hasChanges && id !== 'new' && (
          <div className="buttonContainer">
            <Button onClick={onSaveClick}>Salva</Button>
          </div>
        )}
      </div>
      <div className="buttonContainer">
        <FooterNav
          onNextClick={onNextClick}
          onBackClick={onBackClick}
          nextIsDisabled={
            !(
              data.fiscalData.name &&
              data.fiscalData.address &&
              data.fiscalData.world.id &&
              data.fiscalData.vat &&
              data.fiscalData.iban
            )
          }
        />
      </div>
    </div>
  )
}

export default FiscalData
