import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .topSection': {
      width: '100%',
      height: '100%',
      maxWidth: 500,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflow: 'auto',
      paddingBottom: 15,
      '& .body': {
        width: '100%',
        '& .steps': { padding: 10 },
        '& .resCard': {
          marginTop: 20,
          padding: '0 10px',
          '& > div': { marginTop: 15 },
          '& .textarea': { height: 100 },
          '& .selects': {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            '& > div': { width: '48%' },
          },
        },
        '& .title': {
          height: 40,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: theme.palette.primary.main,
          color: theme.palette.ui.white,
          marginTop: 15,
          fontSize: theme.spacing(2.5),
          fontWeight: 'bold',
        },
        '& .card': {
          padding: 10,
          '& > div': { marginTop: 15 },
        },
      },
    },

    '& .buttonContainer': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      marginTop: 15,
      padding: 10,
      paddingTop: 0,
      maxWidth: 500,
      '& > button': {
        width: '35%',
      },
    },
  },
}))
