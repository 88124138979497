import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 7,
    '& .label': {
      width: '30%',
      maxWidth: '30%',
      background: theme.palette.primary.main,
      color: theme.palette.ui.white,
      padding: 5,
      paddingLeft: 10,
      borderRadius: '5px 0 0 5px',
      fontWeight: 'bold',
      fontSize: theme.spacing(1.8),
    },
    '& .value': {
      padding: 5,
      paddingLeft: 10,
      flex: 1,
      background: '#e4e4e4',
      borderRadius: '0 5px 5px 0',
      fontSize: theme.spacing(1.8),
      fontWeight: 'bold',
      marginLeft: 8,
    },
  },
}))
