import { TextField, Button, Header, MyPagination } from 'src/components'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useNavigate } from 'react-router-dom'

import { useStyle } from './style'

const ResCard = ({ name, address, city, onClick }) => {
  const classes = useStyle()
  return (
    <div className={classes.resCard}>
      <div className="left">
        <span className="name">{name}</span>
        <span className="address">{address}</span>
        <span className="city">{city}</span>
      </div>
      <div className="right">
        <ArrowForwardIosIcon fontSize="large" onClick={onClick} />
      </div>
    </div>
  )
}

const Search = ({
  resName,
  setResName,
  onSearchClick,
  resList,
  isSearched,
  totalElements,
}) => {
  const classes = useStyle()
  const navigate = useNavigate()

  const onNewResClick = () => navigate('/technical-user/new')

  return (
    <div className={classes.container}>
      <div className="search">
        <Header name="Cerca Ristorante" />
        <div className="body">
          <TextField
            onChange={setResName}
            value={resName}
            placeholder="Ristorante..."
          />
          <Button disabled={!resName} onClick={() => onSearchClick(resName)}>
            Cerca
          </Button>
          {!isSearched ? (
            <p className="description">
              Se il ristorante è già presente <br /> in <span>WaitHero</span>{' '}
              <br />
              portai procedere con la sua modifica <br /> altrimenti con la sua
              creazione
            </p>
          ) : (
            resList && (
              <div className="restaurants">
                {resList.map(res => (
                  <ResCard
                    {...{
                      ...res,
                      onClick: () => {
                        navigate(`/technical-user/${res.id}`)
                      },
                    }}
                  />
                ))}
              </div>
            )
          )}
          <div className="pagination">
            {totalElements ? (
              <MyPagination
                onChange={(_, selectedPage) =>
                  console.log(selectedPage) ||
                  onSearchClick(resName, selectedPage)
                }
                // color="#004c6a"
                pagesCount={Math.ceil(totalElements / 10)}
              />
            ) : null}
          </div>
        </div>
      </div>
      <Button onClick={onNewResClick} className="new-res">
        Nuovo Ristorante
      </Button>
    </div>
  )
}

export default Search
