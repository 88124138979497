import { useState, useEffect } from 'react'
import {
  Button,
  Header,
  Steps,
  Select,
  Switch,
  CardField,
  MyModal,
  FooterNav,
} from 'src/components'
import { myOptions } from 'src/helpers/selectOptions'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useToggle } from 'src/helpers/hooks'
import { useNavigate } from 'react-router-dom'
import { useStyle } from './style'

const TableCard = ({ option, cash, onRemoveClick, onEditClick }) => {
  const classes = useStyle()

  return (
    <div onClick={onEditClick} className={classes.tableCard}>
      <div>
        <CardField label="Option" value={option} />
        <CardField label="Cash" value={cash ? 'YES' : 'NO'} />
        {/* <div>
          <span>{day}</span> from <span>{dateToHoursAndMinutes(from)}</span> to
          <span> {dateToHoursAndMinutes(to)}</span>
        </div> */}
      </div>
      <DeleteOutlineIcon fontSize="large" onClick={onRemoveClick} />
    </div>
  )
}

const ResOptions = ({
  itemsList,
  removeItem,
  addItem,
  editItem,
  onNextClick,
  onBackClick,
  isUpdating,
  id,
}) => {
  const classes = useStyle()
  const navigate = useNavigate()

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const [selectedItem, setSelectedItem] = useState('')

  const [deliveryAddedd, setDeliveryAdded] = useState(false)

  const [option, setOption] = useState('')
  const [cash, setCash] = useToggle(true)

  useEffect(() => {
    const foundDelivery = itemsList.find(({ option }) => option === 'DELIVER')
    setDeliveryAdded(foundDelivery ? true : false)
  }, [itemsList, setDeliveryAdded])

  const toggleModal = () => setModalIsOpen(prevState => !prevState)

  const onCancelClick = () => {
    setOption('')
    toggleModal()
    setCash(true)
  }

  const onConfirmClick = () => {
    if (!selectedItem)
      addItem({
        option,
        cash,
        id: new Date(),
      })
    else
      editItem({
        option,

        cash,
        id: selectedItem,
      })
    setOption('')
    setCash(true)
    toggleModal()
    setSelectedItem('')
  }

  const onEditClick = targetId => {
    setSelectedItem(targetId)
    itemsList.forEach(({ id, option, cash }) => {
      if (targetId === id) {
        setOption(option)
        setCash(cash)
        toggleModal()
      }
    })
  }

  return (
    <div className={classes.container}>
      <div className="topSection">
        <Header name="Nuovo Ristorante" />
        <div className="steps">
          <Steps value={6} id={id} isUpdating={isUpdating} />
        </div>
        {/* <p>Some description here</p> */}
        <div className="body">
          {/* <Select
            options={myOptions.resOptions_IT}
            value={option}
            onChange={setOption}
            label="opzine"
          /> */}

          {/* <Switch value={cash} onChange={setCash} label="Cash" /> */}
          {/* <Switch value={enable} onChange={setEnable} label="abilitare" /> */}

          <div className="cardsList">
            {itemsList.map(({ id, option, cash }) => (
              <TableCard
                option={option}
                cash={cash}
                onRemoveClick={e => {
                  removeItem(id)
                  e.stopPropagation()
                }}
                onEditClick={() => onEditClick(id)}
              />
            ))}
          </div>
          <div className="addButtonContainer">
            <AddCircleIcon fontSize="large" onClick={toggleModal} />
          </div>
        </div>
      </div>
      <div className="buttonContainer">
        {/* <Button
          onClick={() => {
            onNextClick()
            navigate(deliveryAddedd ? '/deliveries/new' : '/note/new')
          }}
          className="new-res"
        >
          {!deliveryAddedd ? 'Avanti' : 'Aggiungi consegne'}
        </Button> */}
        <FooterNav
          onNextClick={() => {
            onNextClick()
            navigate(deliveryAddedd ? '/deliveries/new' : '/note/new')
          }}
          onBackClick={onBackClick}
          nextIsDisabled={!itemsList.length}
        />
        <MyModal
          open={modalIsOpen}
          handleClose={toggleModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className={classes.modalBody}>
            <Select
              options={myOptions.resOptions_EN}
              value={option}
              onChange={setOption}
              label="Opzione"
            />

            <Switch label="Cash" onChange={setCash} value={cash} />
            <Button onClick={onConfirmClick}>Salva</Button>
            <div className="cancel" onClick={onCancelClick}>
              Annulla
            </div>
          </div>
        </MyModal>
      </div>
    </div>
  )
}

export default ResOptions
