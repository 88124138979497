import axios from 'axios'
import { reqWrapper } from 'src/helpers/queryWrapper'
import { deleteRequest, getRequest } from 'src/setup/config/axios'

export const getAllRestaurants = reqWrapper(body =>
  axios.post(`/restaurant/all`, body).then(data => data),
)

export const getSingleRestaurant = reqWrapper(id =>
  getRequest(`/restaurant/${id}`),
)

export const updateSingleRetaurant = reqWrapper(body =>
  axios.put('/restaurant', body).then(data => data),
)

export const getCategories = reqWrapper(body =>
  axios.post('/restaurant-category/all', body).then(data => data),
)

export const insertRestaurant = reqWrapper(body =>
  axios.post(`/salesman/init-restaurant`, body).then(data => data),
)

// user

export const getSingleUser = reqWrapper(id =>
  getRequest(`/restaurant-user/${id}`),
)

export const getRestaurantUsers = reqWrapper(body =>
  axios.post('restaurant-user/all', body).then(data => data),
)

export const updateSingleUser = reqWrapper(body =>
  axios.put('/restaurant-user', body).then(data => data),
)

// caterogy

export const getRestaurantCategoriesReq = reqWrapper(body =>
  axios.post('/restaurant-restaurant-category/all', body).then(data => data),
)

export const updateRestaurantCategoriesReq = reqWrapper(body =>
  axios.put('/restaurant-restaurant-category/all', body).then(data => data),
)

export const insertAllCategories = reqWrapper(body =>
  axios
    .post('/restaurant-restaurant-category/insert/all', body)
    .then(data => data),
)

export const deleteAllCategories = reqWrapper(body =>
  deleteRequest('/restaurant-restaurant-category/all', body).then(data => data),
)

// timetable

export const getTimeTables = reqWrapper(body =>
  axios.post('/restaurant-timetable/all', body).then(data => data),
)

export const updateSingleTimeTable = reqWrapper(body =>
  axios.put('/restaurant-timetable', body).then(data => data),
)

export const addSingleTimetable = reqWrapper(body =>
  axios.post('/restaurant-timetable', body).then(data => data),
)

export const deleteSingleTimeTable = reqWrapper(id =>
  deleteRequest(`/restaurant-timetable/${id}`).then(data => data),
)

// tables

export const getTablesReq = reqWrapper(body =>
  axios.post('/restaurant-table/all', body).then(data => data),
)

export const updateSingleTableReq = reqWrapper(body =>
  axios.put('/restaurant-table', body).then(data => data),
)

export const addMultipleTables = reqWrapper(body =>
  axios.post('/restaurant-table/insert/all', body).then(data => data),
)

export const deleteSingleTableReq = reqWrapper(id =>
  deleteRequest(`/restaurant-table/${id}`).then(data => data),
)

// options

export const getOptoinsReq = reqWrapper(body =>
  axios.post('/restaurant-option/all', body).then(data => data),
)

export const updateSingleOptionReq = reqWrapper(body =>
  axios.put('/restaurant-option', body).then(data => data),
)

// deliver

export const getDeliversReq = reqWrapper(body =>
  axios.post('/restaurant-deliver/all', body).then(data => data),
)

export const updateDeliverReq = reqWrapper(body =>
  axios.put('/restaurant-deliver', body).then(data => data),
)
