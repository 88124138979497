import { useCallback } from 'react'
import {
  TextField,
  Header,
  Steps,
  PhoneInput,
  FooterNav,
  Button,
} from 'src/components'
import { useStyle } from './style'

const TechnicalUser = ({
  isNew,
  data = {},
  changeData,
  onNextClick,
  id,
  onBackClick,
  hasChanges,
  onSaveClick,
  checkForChanges,
}) => {
  const classes = useStyle()

  const onDataChange = useCallback(
    (key, subKey = '') =>
      value => {
        changeData(key, subKey, value)
      },
    [changeData],
  )

  return (
    <div className={classes.container}>
      <div className="topSection">
        <Header name={!isNew ? 'Modifica Ristorante' : 'Nuovo Ristorante'} />
        <div className="body">
          <div className="steps">
            <Steps value={1} id={id} checkForChanges={checkForChanges} />
          </div>

          {/* <div className="title">Utente tecnico</div> */}
          <div className="card">
            <TextField
              label="Nome"
              onChange={onDataChange('name')}
              value={data.name}
            />
            <TextField
              label="Cognome"
              onChange={onDataChange('surname')}
              value={data.surname}
            />
            <TextField
              label="Email"
              onChange={onDataChange('email')}
              value={data.email}
            />

            <PhoneInput
              label="Telefono"
              onChange={onDataChange('phone')}
              value={data.phone}
            />
          </div>
        </div>
        {hasChanges && id !== 'new' && (
          <div className="buttonContainer">
            <Button onClick={onSaveClick}>Salva</Button>
          </div>
        )}
      </div>

      <div className="buttonContainer">
        <FooterNav
          onNextClick={onNextClick}
          onBackClick={onBackClick}
          nextIsDisabled={
            !(data.name && data.surname && data.email && data.phone)
          }
        />
      </div>
    </div>
  )
}

export default TechnicalUser
