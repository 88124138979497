import { alpha, makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(theme => ({
  container: {
    width: '100%',
    position: 'relative',
    '& > span': {
      position: 'absolute',
      left: 15,
      top: -12,
      color: theme.palette.primary.main,
      fontSize: theme.spacing(2),
      zIndex: 1,
      background: '#fafafa',
      borderRadius: 20,
      padding: '0 4px',
    },
  },
  root: {
    height: 45,
    minHeight: ({ multiline }) => (multiline ? 120 : 40),
  },
  input: {
    borderRadius: 5,
    position: 'relative',
    border: '1px solid ',
    boxSizing: 'border-box',
    minHeight: ({ multiline }) => (multiline ? 120 : 40),
    color: theme.palette.primary.main,
    borderColor: ({ hasError }) =>
      hasError ? theme.palette.ui.red : theme.palette.primary.main,
    fontSize: theme.spacing(2),
    lineHeight: '21px',
    letterSpacing: -0.08,
    height: '100%',
    maxHeight: 120,
    overflow: 'auto',
    padding: ({ multiline }) => (multiline ? '10px 12px' : '0 12px'),
    transition: 'border-color 0.2s, box-shadow 0.2s',
    '&:focus': {
      boxShadow: ({ hasError }) =>
        `${alpha(
          hasError ? theme.palette.ui.red : theme.palette.ui.blue,
          0.8,
        )} 0 0 0 0.1rem`,
      borderColor: ({ hasError }) =>
        hasError ? theme.palette.ui.red : theme.palette.primary.main,
    },
    '&::placeholder': {
      color: theme.palette.primary.main,
      weight: 300,
    },
  },
}))
