import { useStyle } from './style'

const CardField = ({ className, label = '', value = '' }) => {
  const classes = useStyle()

  return (
    <div className={classes.container}>
      <span className="label">{label}</span>
      <span className="value">{value}</span>
    </div>
  )
}

export default CardField
