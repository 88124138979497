import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import { useStyle } from './style'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 400,
  bgcolor: 'background.paper',
  border: '2px solid #004c6a',
  boxShadow: 24,
  p: 2,
}

const MyModal = ({ children, open, handleClose }) => {
  const classes = useStyle()

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      classes={{ root: classes.root }}
    >
      <Box sx={style}>{children}</Box>
    </Modal>
  )
}

export default MyModal
