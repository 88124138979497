import { useState, useCallback } from 'react'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import {
  TextField,
  Button,
  Header,
  Steps,
  MyModal,
  CardField,
  MyPagination,
  FooterNav,
} from 'src/components'
import { useToggle } from 'src/helpers/hooks'
import { useStyle } from './style'

const TableCard = ({ name, rank, onRemoveClick, onEditClick }) => {
  const classes = useStyle()

  return (
    <div onClick={onEditClick} className={classes.tableCard}>
      <div>
        <CardField label="Name" value={name} />
        <CardField label="Rank" value={rank} />
      </div>
      <DeleteOutlineIcon fontSize="large" onClick={onRemoveClick} />
    </div>
  )
}

const ResTables = ({
  itemsList,
  removeItem,
  addItem,
  editItem,
  onNextClick,
  onBackClick,
  addItemAPI,
  updateItemAPI,
  deleteItemAPI,
  isUpdating,
  totalElements,
  onPageClick,
  id,
  currentPage,
}) => {
  const classes = useStyle()

  const [modalIsOpen, toggleModal] = useToggle(false)
  const [editModalIsOpen, toggleEditModal] = useToggle(false)

  const [selectedItem, setSelectedItem] = useState('')
  const [name, setName] = useState('')
  const [numberOfTables, setNumberOfTables] = useState(1)
  const [rank, setRank] = useState(1)

  const onCancelClick = () => {
    setName('')
    setRank(0)
    setNumberOfTables(0)
    setSelectedItem('')
    if (selectedItem) toggleEditModal()
    else toggleModal()
  }

  const onAddConfirmClick = () => {
    if (isUpdating) {
      const items = []
      for (let i = rank; i <= Number(rank) + Number(numberOfTables) - 1; i++)
        items.push({ name: `${name}_${i}`, rank: i })
      addItemAPI(items)
    } else {
      for (let i = rank; i <= Number(rank) + Number(numberOfTables) - 1; i++)
        addItem({
          name: `${name}_${i}`,
          rank: i,
          id: new Date(),
        })
    }
    setName('')
    setRank(0)
    setNumberOfTables(0)
    toggleModal()
    setSelectedItem('')
  }

  const onEditClick = targetId => {
    setSelectedItem(targetId)
    itemsList.forEach(({ id, name, rank }) => {
      if (targetId === id) {
        setName(name)
        setRank(rank)
        toggleEditModal()
      }
    })
  }

  const onEditConfirmClick = () => {
    if (isUpdating) {
      const item = { id: selectedItem, name, rank }
      updateItemAPI(item).then(() => toggleEditModal())
    } else {
      editItem({
        name,
        rank,
        id: selectedItem,
      })
      toggleEditModal()
    }
    setName('')
    setRank(0)
    setNumberOfTables(0)
    setSelectedItem('')
  }

  const onRemoveClick = useCallback(
    id => {
      if (isUpdating) {
        deleteItemAPI(id)
      } else {
        removeItem(id)
      }
    },
    [isUpdating, removeItem, deleteItemAPI],
  )

  return (
    <div className={classes.container}>
      <div className="topSection">
        <Header name="Nuovo Ristorante" />
        <div className="steps">
          <Steps isUpdating={isUpdating} id={id} value={5} />
        </div>
        {/* <p>Some description here</p> */}
        <div className="body">
          <div className="cardsList">
            {itemsList.map(({ name, rank, id }) => (
              <TableCard
                name={name}
                rank={rank}
                onRemoveClick={e => {
                  onRemoveClick(id)
                  e.stopPropagation()
                }}
                onEditClick={() => onEditClick(id)}
              />
            ))}
          </div>
          <div className="addButtonContainer">
            <AddCircleIcon fontSize="large" onClick={toggleModal} />
          </div>
        </div>
        <div className="pagination">
          {totalElements ? (
            <MyPagination
              onChange={(_, selectedPage) => onPageClick(selectedPage)}
              // color="#004c6a"
              pagesCount={Math.ceil(totalElements / 10)}
              currentPage={currentPage}
            />
          ) : null}
        </div>
      </div>

      <div className="buttonContainer">
        {/* <Button
          disabled={!itemsList.length}
          onClick={onNextClick}
          className="new-res"
        >
          {!isUpdating ? 'Aggiungi opzioni' : 'Torna alla ricerca'}
        </Button> */}
        <FooterNav
          onNextClick={onNextClick}
          onBackClick={onBackClick}
          nextIsDisabled={!itemsList.length}
        />
        <MyModal
          open={modalIsOpen}
          handleClose={toggleModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className={classes.modalBody}>
            <TextField
              value={numberOfTables}
              onChange={setNumberOfTables}
              label="Quanti ?"
            />
            <TextField value={name} onChange={setName} label="Nome" />
            <TextField
              value={rank}
              onChange={setRank}
              label="Indice di partenza"
            />
            <Button disabled={!name} onClick={onAddConfirmClick}>
              Salva
            </Button>
            <div className="cancel" onClick={onCancelClick}>
              Annulla
            </div>
          </div>
        </MyModal>
        <MyModal
          open={editModalIsOpen}
          handleClose={toggleEditModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className={classes.modalBody}>
            <TextField value={name} onChange={setName} label="Nome" />
            <TextField value={rank} onChange={setRank} label="Rango" />
            <Button onClick={onEditConfirmClick}>Salva</Button>
            <div className="cancel" onClick={onCancelClick}>
              Annulla
            </div>
          </div>
        </MyModal>
      </div>
    </div>
  )
}

export default ResTables
