import { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  getTimeTables,
  addSingleTimetable,
  updateSingleTimeTable,
  deleteSingleTimeTable,
} from 'src/services/restaurant'
import { setIsLoading, setVisitedSteps } from '../_slice/view.slice'
import {
  loadTimeTables,
  restaurantTimeTablesView,
} from '../_slice/restaurant.slice'
import Timing from './timing'
import { deleteItemAlert } from 'src/helpers/alerts'
import { useParams } from 'react-router-dom'

const fakeItem = {
  dateInsert: '2022-06-14T13:39:42.235Z',
  dateUpdate: '2022-06-14T13:39:42.235Z',
  // order: {
  //   idRestaurant: 'string',
  //   timeFrom: 'string',
  //   timeTo: 'string',
  //   weekday: 'string',
  // },
  // translation: {
  //   answer: 'string',
  //   content: 'string',
  //   description: 'string',
  //   language: 'string',
  //   link: 'string',
  //   name: 'string',
  //   question: 'string',
  //   subtitle: 'string',
  //   title: 'string',
  //   tutorial: 'string',
  //   value: 'string',
  // },
  userInsert: 0,
  userUpdate: 0,
}

export default () => {
  const { id } = useParams()
  const [timesList, setTimesList] = useState([])
  const isUpdating = useMemo(() => id !== 'new', [id])

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const loadTimeTablesfromAPI = (triggerLoading = false) => {
    getTimeTables({ idRestaurant: id }).then(data => {
      setTimesList(data.list)
      if (triggerLoading) dispatch(setIsLoading(false))
    })
  }

  const timesLocal = useSelector(restaurantTimeTablesView)

  useEffect(() => {
    if (id === 'new') {
      dispatch(setVisitedSteps(4))
      setTimesList(timesLocal)
      return
    }
    dispatch(setIsLoading(true))
    loadTimeTablesfromAPI(true)
  }, [id, timesLocal])

  const removeItem = targetId =>
    setTimesList(prevState => prevState.filter(({ id }) => id !== targetId))

  const addItem = item => setTimesList(prevState => [...prevState, item])

  const editItem = targetItem =>
    setTimesList(prevState =>
      prevState.map(item => (targetItem.id === item.id ? targetItem : item)),
    )

  const onNextClick = () => {
    if (!isUpdating) {
      dispatch(loadTimeTables(timesList))
      navigate('/restaurant-tables/new')
    } else navigate(`/restaurant-tables/${id}`)
  }

  const onBackClick = () => {
    if (!isUpdating) {
      navigate('/fiscal-data/new')
    } else navigate(`/fiscal-data/${id}`)
  }

  const updateItemAPI = item => {
    const myItem = {
      ...timesList.find(t => t.id === item.id),
      ...item,
    }
    dispatch(setIsLoading(true))
    updateSingleTimeTable(myItem).then(() => {
      loadTimeTablesfromAPI(true)
    })
  }

  const addItemAPI = item => {
    const myItem = { ...fakeItem, ...item, idRestaurant: id }
    dispatch(setIsLoading(true))
    addSingleTimetable(myItem).then(() => {
      loadTimeTablesfromAPI(true)
    })
  }

  const deleteItemAPI = id => {
    deleteItemAlert(() => {
      dispatch(setIsLoading(true))
      deleteSingleTimeTable(id).then(() => {
        console.log('reached')
        loadTimeTablesfromAPI(true)
      })
    })
  }

  return (
    <Timing
      {...{
        timesList,
        updateItemAPI,
        addItemAPI,
        isUpdating,
        removeItem,
        addItem,
        editItem,
        onNextClick,
        onBackClick,
        deleteItemAPI,
        id,
      }}
    />
  )
}
