import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  TextField,
  FooterNav,
  Header,
  Steps,
  MyModal,
  CardField,
  Button,
} from 'src/components'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { useStyle } from './style'

const DeliveryCard = ({
  kmFrom,
  kmTo,
  amount,
  minCartAmount,
  maxCartAmount,
  onRemoveClick,
  onEditClick,
}) => {
  const classes = useStyle()

  return (
    <div onClick={onEditClick} className={classes.deliveryCard}>
      <div>
        <div>
          <span>Distanza</span> da <span>{kmFrom}</span> a<span> {kmTo} </span>{' '}
          KM
        </div>
        <CardField label="Importo" value={amount} />
        <div>
          <span>Importo del carrello</span> da <span>{minCartAmount}</span> a
          <span> {maxCartAmount}</span>
        </div>
      </div>
      <DeleteOutlineIcon fontSize="large" onClick={onRemoveClick} />
    </div>
  )
}

const Deliveries = ({
  itemsList,
  addItem,
  removeItem,
  editItem,
  onNextClick,
  onBackClick,
  id,
  isUpdating,
}) => {
  const classes = useStyle()
  const navigate = useNavigate()

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const [selectedItem, setSelectedItem] = useState('')

  const toggleModal = () => setModalIsOpen(prevState => !prevState)

  const [kmFrom, setKmFrom] = useState('')
  const [kmTo, setKmTo] = useState('')

  const [minCartAmount, setMinCartAmount] = useState('')
  const [maxCartAmount, setMaxCartAmount] = useState('')

  const [amount, setAmount] = useState('')

  const onCancelClick = () => {
    setKmFrom('')
    setKmTo('')
    setAmount('')
    setMinCartAmount('')
    setMaxCartAmount('')
    toggleModal('')
  }

  const onConfirmClick = () => {
    if (!selectedItem)
      addItem({
        kmFrom,
        kmTo,
        amount,
        minCartAmount,
        maxCartAmount,
        id: new Date(),
      })
    else
      editItem({
        kmFrom,
        kmTo,
        amount,
        minCartAmount,
        maxCartAmount,
        id: selectedItem,
      })
    setKmFrom('')
    setKmTo('')
    setAmount('')
    setMinCartAmount('')
    setMaxCartAmount('')
    toggleModal()
    setSelectedItem('')
  }

  const onEditClick = targetId => {
    setSelectedItem(targetId)
    itemsList.forEach(
      ({ id, kmFrom, kmTo, amount, minCartAmount, maxCartAmount }) => {
        if (targetId === id) {
          setKmFrom(kmFrom)
          setKmTo(kmTo)
          setAmount(amount)
          setMinCartAmount(minCartAmount)
          setMaxCartAmount(maxCartAmount)
          toggleModal()
        }
      },
    )
  }

  return (
    <div className={classes.container}>
      <div className="topSection">
        <Header name="Nuovo Ristorante" />
        <div className="steps">
          <Steps value={isUpdating ? '' : 7} id={id} />
        </div>
        {/* <p>Some description here</p> */}
        <div className="body">
          <div className="cardsList">
            {itemsList.map(
              ({ kmFrom, kmTo, amount, minCartAmount, maxCartAmount, id }) => (
                <DeliveryCard
                  kmFrom={kmFrom}
                  kmTo={kmTo}
                  amount={amount}
                  minCartAmount={minCartAmount}
                  maxCartAmount={maxCartAmount}
                  onRemoveClick={e => {
                    removeItem(id)
                    e.stopPropagation()
                  }}
                  onEditClick={() => onEditClick(id)}
                />
              ),
            )}
          </div>
          <div className="addButtonContainer">
            <AddCircleIcon fontSize="large" onClick={toggleModal} />
          </div>
        </div>
      </div>
      <div className="buttonContainer">
        <FooterNav
          onNextClick={onNextClick}
          onBackClick={onBackClick}
          nextIsDisabled={!itemsList.length}
        />
        <MyModal
          open={modalIsOpen}
          handleClose={toggleModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className={classes.modalBody}>
            <TextField
              value={kmFrom}
              onChange={v => setKmFrom(Number(v))}
              label="Da (km)"
            />
            <TextField
              value={kmTo}
              onChange={v => setKmTo(Number(v))}
              label="A (km)"
            />
            <TextField
              value={minCartAmount}
              onChange={v => setMinCartAmount(Number(v))}
              label="Ordine minimo (€)"
            />
            <TextField
              value={maxCartAmount}
              onChange={v => setMaxCartAmount(Number(v))}
              label="Importo per consegna gratuita (€)"
            />
            <TextField
              value={amount}
              onChange={v => setAmount(Number(v))}
              label="Costo di consegna (€)"
            />

            <Button
              onClick={onConfirmClick}
              disabled={
                !(kmFrom && kmTo && minCartAmount && maxCartAmount && amount)
              }
            >
              Salva
            </Button>
            <div className="cancel" onClick={onCancelClick}>
              Annulla
            </div>
          </div>
        </MyModal>
      </div>
    </div>
  )
}

export default Deliveries
