import { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  loadDeliveries,
  restaurantDeliveriesVeiw,
} from '../_slice/restaurant.slice'
import { setIsLoading } from '../_slice/view.slice'
import { getDeliversReq, updateDeliverReq } from 'src/services/restaurant'
import Deliveries from './deliveries'
import { useParams } from 'react-router-dom'

export default () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const isUpdating = useMemo(() => id !== 'new', [id])
  const [itemsList, setItemsList] = useState([])

  // const [distanceRange, setDistanceRange] = useState([0, 20])
  // const [cartRange, setCartRange] = useState([0, 20])
  // const [amount, setAmount] = useState('')

  const loadOptionsFromAPI = (triggerLoading = false, page = 1) => {
    getDeliversReq({
      idRestaurant: id,
    }).then(res => {
      if (triggerLoading) dispatch(setIsLoading(false))
    })
  }

  const deliveriesLocal = useSelector(restaurantDeliveriesVeiw)

  useEffect(() => {
    if (id === 'new') {
      setItemsList(deliveriesLocal)
      return
    }
    dispatch(setIsLoading(true))
    loadOptionsFromAPI(true)
  }, [id, deliveriesLocal])

  const removeItem = targetId =>
    setItemsList(prevState => prevState.filter(({ id }) => id !== targetId))

  const addItem = item => setItemsList(prevState => [...prevState, item])

  const editItem = targetItem =>
    setItemsList(prevState =>
      prevState.map(item => (targetItem.id === item.id ? targetItem : item)),
    )

  const onNextClick = () => {
    if (isUpdating) {
      updateDeliverReq({}).then(res => {})
      navigate(`/note/${id}`)
    } else {
      dispatch(loadDeliveries(itemsList))
      navigate('/note/new')
    }
  }

  const onBackClick = () => {
    if (isUpdating) navigate(`/restaurant-options/${id}`)
    else navigate('/restaurant-options/new')
  }

  return (
    <Deliveries
      {...{
        itemsList,
        addItem,
        removeItem,
        editItem,
        onNextClick,
        id,
        isUpdating,
        onBackClick,
      }}
    />
  )
}
