import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(theme => ({
  root: {
    '& > div': {
      borderRadius: 5,
      '& > div': { color: theme.palette.primary.main },
    },
    position: 'relative',
    '& > span': {
      position: 'absolute',
      left: 15,
      top: -12,
      color: theme.palette.primary.main,
      fontSize: theme.spacing(2),
      zIndex: 1,
      background: '#fafafa',
      borderRadius: 20,
      padding: '0 4px',
    },
  },

  select: {
    '& .MuiSelect-icon': {
      color: theme.palette.primary.main,
      fontSize: '2.2rem',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.primary.main}`,
    },

    '& .MuiInputLabel-root': {
      color: 'red',
    },
  },
}))
