import { useState } from 'react'
import Menu from '@mui/material/Menu'
import { useStyle } from './style'

const MyMenu = ({ children, open, anchorEl, handleClose }) => {
  const classes = useStyle()
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {children}
    </Menu>
  )
}

export default MyMenu
