import { Header, Steps, Switch, TextField, FooterNav } from 'src/components'
import { useStyle } from './style'

const Note = ({
  prints,
  setPrints,
  printer,
  setPrinter,
  tablet,
  setTablet,
  flatMenuEdit,
  setFlatMenuEdit,
  noteText,
  setNoteText,
  onSaveClick,
  onBackClick,
  id,
  isUpdating,
}) => {
  const classes = useStyle()

  return (
    <div className={classes.container}>
      <div className="topSection">
        <Header name="Nuovo Ristorante" />
        <div className="steps">
          <Steps value={isUpdating ? 6 : 8} id={id} />
        </div>
        <div className="body">
          <Switch
            value={prints}
            onChange={setPrints}
            label="Personalizzazione stampa"
          />
          <Switch value={printer} onChange={setPrinter} label="stampante" />
          <Switch value={tablet} onChange={setTablet} label="Tablet" />
          <Switch
            value={flatMenuEdit}
            onChange={setFlatMenuEdit}
            label="modalità flat modifiche menu"
          />
          <TextField
            value={noteText}
            label="Nota"
            onChange={setNoteText}
            multiline
          />
        </div>
      </div>
      <div className="buttonContainer">
        <FooterNav onNextClick={onSaveClick} onBackClick={onBackClick} />
        {/* <Button
          onClick={() => {
            onSaveClick()
          }}
          className="new-res"
        >
          Salva
        </Button> */}
      </div>
    </div>
  )
}

export default Note
