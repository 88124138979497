import { useState } from 'react'

export const useToggle = (initialValue = false) => {
  const [value, setValue] = useState(initialValue)
  return [
    value,
    forcedValue =>
      setValue(flag =>
        typeof forcedValue === 'boolean' ? forcedValue : !flag,
      ),
  ]
}
