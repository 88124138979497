import { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { loadTables, restaurantTablesView } from '../_slice/restaurant.slice'
import { setIsLoading, setVisitedSteps } from '../_slice/view.slice'
import { deleteItemAlert } from 'src/helpers/alerts'
import {
  getTablesReq,
  updateSingleTableReq,
  addMultipleTables,
  deleteSingleTableReq,
} from 'src/services/restaurant'
import ResTables from './resTables'

const fakeTable = {
  dateInsert: '2022-06-14T13:52:11.241Z',
  dateUpdate: '2022-06-14T13:52:11.241Z',
  enable: true,
  externalId: 'string',
  // order: {
  //   enable: 'string',
  //   idRestaurant: 'string',
  //   name: 'string',
  //   zpl: 'string',
  // },
  // translation: {
  //   answer: 'string',
  //   content: 'string',
  //   description: 'string',
  //   language: 'string',
  //   link: 'string',
  //   name: 'string',
  //   question: 'string',
  //   subtitle: 'string',
  //   title: 'string',
  //   tutorial: 'string',
  //   value: 'string',
  // },
  userInsert: 0,
  userUpdate: 0,
  zpl: 'string',
}

export default () => {
  const { id } = useParams()
  const [itemsList, setItemsList] = useState([])
  const [totalElements, setTotalElements] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const isUpdating = useMemo(() => id !== 'new', [id])

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const loadTablesFromAPI = (triggerLoading = false, page = 1) =>
    getTablesReq({
      idRestaurant: id,
      pagination: { elementsPerPage: 10, page },
    }).then(({ list, totalElements }) => {
      setItemsList(list)
      setTotalElements(totalElements)
      if (triggerLoading) dispatch(setIsLoading(false))
    })

  const tablesLocal = useSelector(restaurantTablesView)

  useEffect(() => {
    if (id === 'new') {
      dispatch(setVisitedSteps(5))
      setItemsList(tablesLocal)
      return
    }
    dispatch(setIsLoading(true))
    loadTablesFromAPI(true)
  }, [id, tablesLocal])

  const removeItem = targetId =>
    setItemsList(prevState => prevState.filter(({ id }) => id !== targetId))

  const addItem = item => setItemsList(prevState => [...prevState, item])

  const editItem = targetItem =>
    setItemsList(prevState =>
      prevState.map(item => (targetItem.id === item.id ? targetItem : item)),
    )

  const onNextClick = () => {
    if (!isUpdating) {
      dispatch(loadTables(itemsList))
      navigate('/restaurant-options/new')
    } else navigate('/search')
  }

  const onBackClick = () => {
    if (!isUpdating) navigate('/timing/new')
    else navigate(`/timing/${id}`)
  }

  const updateItemAPI = item => {
    const myItem = {
      ...itemsList.find(t => t.id === item.id),
      ...item,
    }
    dispatch(setIsLoading(true))
    return updateSingleTableReq(myItem).then(() => {
      loadTablesFromAPI(true).then(() => setCurrentPage(1))
    })
  }

  const addItemAPI = items => {
    const myItems = items.map(it => ({ ...fakeTable, ...it, idRestaurant: id }))
    dispatch(setIsLoading(true))
    return addMultipleTables(myItems).then(res => {
      loadTablesFromAPI(true).then(() => setCurrentPage(1))
    })
  }

  const deleteItemAPI = id => {
    deleteItemAlert(() => {
      dispatch(setIsLoading(true))
      deleteSingleTableReq(id).then(() =>
        loadTablesFromAPI(true).then(() => setCurrentPage(1)),
      )
    })
  }

  const onPageClick = newPage => {
    dispatch(setIsLoading(true))
    loadTablesFromAPI(true, newPage)
    setCurrentPage(newPage)
  }

  return (
    <ResTables
      {...{
        itemsList,
        removeItem,
        addItem,
        editItem,
        onNextClick,
        onBackClick,
        addItemAPI,
        updateItemAPI,
        deleteItemAPI,
        isUpdating,
        totalElements,
        onPageClick,
        id,
        currentPage,
      }}
    />
  )
}
