import { alpha, makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(theme => ({
  container: {
    width: '100%',
    position: 'relative',
    '& > span': {
      position: 'absolute',
      left: 15,
      top: -12,
      color: theme.palette.primary.main,
      fontSize: theme.spacing(2),
      zIndex: 1,
      background: '#fafafa',
      borderRadius: 20,
      padding: '0 4px',
    },
    '& > div': {
      width: '100%',
      border: '1px solid',
      borderColor: theme.palette.primary.main,
      borderRadius: 5,
      padding: '10px 5px 5px 5px',

      '& > div': {
        border: 'none !important',
      },
    },

    '& .Mui-focused': {
      borderBottom: '1px solid black',
    },

    '& .css-1ptx2yq-MuiInputBase-root-MuiInput-root:before': {
      borderBottom: 'none !important',
    },

    '& input': {
      color: theme.palette.primary.main,
      border: 'none !important',
    },

    '& svg': {
      maxHeight: 20,
    },
  },
}))
