import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import { useStyle } from './style'

const MyPagination = ({ pagesCount, color, onChange, currentPage }) => {
  const classes = useStyle()

  return (
    <Stack spacing={2}>
      <Pagination
        count={pagesCount}
        onChange={onChange}
        color={color || 'primary'}
        classes={{ ul: classes.ul }}
        page={currentPage}
      />
    </Stack>
  )
}

export default MyPagination
