import { useCallback } from 'react'
import {
  TextField,
  FooterNav,
  Header,
  Steps,
  Select,
  PhoneInput,
  AutoComplete,
  Button,
} from 'src/components'
import { useStyle } from './style'

const SingleRes = ({
  isNew,
  data = {},
  cities,
  categories,
  changeData,
  onNextClick,
  onBackClick,
  setResCity,
  id,
  checkForChanges,
  onSaveClick,
  hasChanges,
}) => {
  const classes = useStyle()

  const onDataChange = useCallback(
    (key, subKey = '') =>
      value => {
        changeData(key, subKey, value)
      },
    [changeData],
  )

  return (
    <div className={classes.container}>
      <div className="topSection">
        <Header name={!isNew ? 'Modifica Ristorante' : 'Nuovo Ristorante'} />
        <div className="body">
          <div className="steps">
            <Steps value={2} id={id} checkForChanges={checkForChanges} />
          </div>
          <div className="resCard">
            <TextField
              onChange={onDataChange('name')}
              label="Nome"
              value={data.name}
            />
            <TextField
              multiline
              onChange={value => changeData('location', 'address', value)}
              label="Indirizzo"
              value={data.location.address}
            />
            <Select
              options={cities}
              onChange={value => {
                changeData('location', 'world', {
                  ...data.location.world,
                  id: value,
                })
                const city = cities.find(({ value: val }) => val === value)
                setResCity(city.key)
              }}
              label="Città"
              value={data.location.world.id}
            />
            <TextField
              onChange={value => changeData('location', 'postalCode', value)}
              label="Codice Postale"
              value={data.location.postalCode}
            />
            <PhoneInput
              label="Telefono"
              onChange={value => changeData('phone', '', value)}
              value={data.phone}
            />
            <TextField
              label="Email"
              onChange={value => changeData('email', '', value)}
              value={data.email}
            />
            {
              <AutoComplete
                options={categories}
                multiple
                value={data.category || []}
                onChange={values => {
                  changeData('category', '', values)
                }}
                label="Categorie"
              />
            }
          </div>
        </div>
        {hasChanges && id !== 'new' && (
          <div className="buttonContainer">
            <Button onClick={onSaveClick}>Salva</Button>
          </div>
        )}
      </div>

      <div className="buttonContainer">
        <FooterNav
          onNextClick={onNextClick}
          onBackClick={onBackClick}
          nextIsDisabled={
            !(
              data.name &&
              data.location.address &&
              data.location.world.id &&
              data.phone.length > 3 &&
              data.email &&
              data.category &&
              data.category.length
            )
          }
        />
      </div>
    </div>
  )
}

export default SingleRes
