import { useState, useEffect, useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import * as R from 'ramda'
import { warningAlert } from 'src/helpers/alerts'
import FiscalData from './fiscalData'
import {
  getSingleRestaurant,
  updateSingleRetaurant,
} from 'src/services/restaurant'
import { setIsLoading, setVisitedSteps } from '../_slice/view.slice'
import { getLocationReq } from 'src/services/location'
import { worldCitiesView } from '../_slice/world.slice'
import {
  loadFiscalData,
  restaurantFiscalDataView,
} from '../_slice/restaurant.slice'
import { convertCitiesToOptions } from 'src/helpers/functions'

export default () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [data, setData] = useState({
    // location: { world: {} },
    fiscalData: { world: {} },
  })
  const [initialData, setInitialData] = useState({
    // location: { world: {} },
    fiscalData: { world: {} },
  })
  const rawCities = useSelector(worldCitiesView)
  const cities = useMemo(
    () => convertCitiesToOptions(rawCities || []),
    [rawCities],
  )

  const fiscalDataLocal = useSelector(restaurantFiscalDataView)

  const loadData = () => {
    dispatch(setIsLoading(true))
    Promise.all([
      getSingleRestaurant(id).then(data => {
        setData(prevState => ({ ...prevState, ...data }))
        setInitialData(prevState => ({ ...prevState, ...data }))
      }),
    ]).then(() => {
      dispatch(setIsLoading(false))
    })
  }

  useEffect(() => {
    if (id === 'new') {
      dispatch(setVisitedSteps(3))
      setData({ fiscalData: fiscalDataLocal })
      return
    }
    loadData()
  }, [id, dispatch])

  const hasChanges = useMemo(
    () => !R.equals(data, initialData),
    [data, initialData],
  )

  const setLocation = query => {
    getLocationReq(query).then(({ list }) => {
      setData(prevState => ({ ...prevState, category: list }))
    })
  }

  const onSaveClick = () => {
    dispatch(setIsLoading(true))
    const { fiscalData, ...rest } = data
    updateSingleRetaurant({
      ...rest,
      fiscalData,
    }).then(() => {
      loadData()
    })
  }

  const checkForChanges = pathToNavigate => {
    if (hasChanges) {
      warningAlert({
        message: 'modifiche non salvate',
        detail: 'Sei sicuro di procedere senza salvare?',
        callback: () => navigate(pathToNavigate),
      })
    } else navigate(pathToNavigate)
  }

  const onNextClick = () => {
    const { fiscalData } = data
    // getLocationReq(`${location.address} ${resCity}`)
    //   .then(({ data: locData }) => {
    //     const { latitude, longitude } = locData[0]

    // uddating by calling API's
    if (id !== 'new') {
      checkForChanges(`/timing/${id !== 'new' ? id : 'new'}`)
    }
    // local stuff
    else {
      dispatch(loadFiscalData(fiscalData))
      dispatch(setIsLoading(false))
      navigate(`/timing/${id !== 'new' ? id : 'new'}`)
    }
    // })
    // .catch(err => {
    //   errorAlert({
    //     message: err,
    //   })
    // })
  }

  const onBackClick = () => {
    if (id !== 'new')
      checkForChanges(`/single-restaurant/${id !== 'new' ? id : 'new'}`)
    else navigate(`/single-restaurant/${id !== 'new' ? id : 'new'}`)
  }

  const changeData = (key, childKey = '', value) =>
    setData(prevState =>
      !childKey
        ? { ...prevState, [key]: value }
        : { ...prevState, [key]: { ...prevState[key], [childKey]: value } },
    )

  return (
    <FiscalData
      {...{
        changeData,
        onBackClick,
        cities,
        data,
        id,
        isNew: id === 'new',
        onNextClick,
        setLocation,
        hasChanges,
        checkForChanges,
        onSaveClick,
      }}
    />
  )
}
